<template>
  <div class="w-full">
    <div class="flex gap-2 rounded-lg">
      <Button
        styles="secondary"
        class="h-10 w-44 mt-auto"
        @click.native="$router.push('/dashboard/tariffs/create')"
      >
        <a-icon type="plus" />
        Crear producto
      </Button>
      <Button
        styles="secondary"
        :invert="true"
        class="h-10 w-44 mt-auto"
        @click.native="
          $router.push({
            path: '/dashboard/tariffs/create',
            query: { massive: true },
          })
        "
      >
        <a-icon type="upload" />
        Cargar masivo
      </Button>
    </div>
    <div class="flex rounded-lg mb-4 border border-gray-100 max-w-sm">
      <BaseInput
        type="text"
        label="Buscar por código o tipo de formato:"
        v-model="searchTerm"
        placeholder="Ej: deposito, 82970388"
        autocomplete="off"
      />
    </div>
    <div
      v-if="tariffs"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300"
    >
      <a-table
        :columns="columns"
        :data-source="filteredDataByTerm"
        :loading="loading"
      >
        <template slot="fecha_reporte" slot-scope="text, record">
          <p>
            Fecha: {{ getFormattedDate(record.fecha_reporte) }} <br />
            Hora: {{ getDateTime(record.fecha_reporte) }}
          </p>
        </template>
        <template slot="format" slot-scope="text, record">
          {{ getTariffNameOption(record.format) }}
        </template>
        <template slot="activo" slot-scope="text, record">
          {{ record.activo ? 'Activo' : 'Desactivado' }}
        </template>
        <template slot="actions" slot-scope="text, record">
          <div class="flex gap-2">
            <Button class="w-24" @click.native="goToViewTariff(record)">
              <a-icon type="eye" /> Ver
            </Button>
            <Button
              styles="secondary"
              :invert="true"
              class="w-24"
              @click.native="goToEditTariff(record)"
            >
              <a-icon type="edit" /> Editar
            </Button>
          </div>
        </template>

        <!-- FILTERS -->
      </a-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getFormattedDate, getDateTime } from '@/utils/date'

const initialPagination = {
  current: 1,
  pageSize: 10,
  hideOnSinglePage: true,
  position: 'bottom',
}

export default {
  components: {},
  data() {
    return {
      searchTerm: '',
      legend: '',
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      showModal: false,
      pagination: { ...initialPagination },
      getFormattedDate,
      getDateTime,
    }
  },
  computed: {
    ...mapState({
      optionsStatus: (state) => {
        let options = state.form.optionsStatus.map((option) => {
          return { text: option.name, value: option.id }
        })
        return options
      },
      formOptions: 'form',
    }),
    ...mapState('tariffs', ['tariffs']),
    columns() {
      const columns = [
        {
          title: 'Código registro',
          dataIndex: 'codigo_registro',
          key: 'codigo_registro',
        },
        {
          title: 'Fecha reporte',
          dataIndex: 'fecha_reporte',
          key: 'fecha_reporte',

          sorter: (a, b) => {
            return new Date(a.fecha_reporte) - new Date(b.fecha_reporte)
          },
          scopedSlots: { customRender: 'fecha_reporte' },
        },
        {
          title: 'Nombre comercial',
          dataIndex: 'nombre_comercial',
          key: 'nombre_comercial',
          width: '200px',
          sorter: (a, b, sorter) => {
            let variableA = a.nombre_comercial ? a.nombre_comercial : ''
            let variableB = b.nombre_comercial ? b.nombre_comercial : ''
            if (sorter === 'ascend') {
              return variableA
                ? variableB
                  ? variableA.localeCompare(variableB)
                  : -1
                : 1
            }
            return !variableA && !variableB
              ? !variableA > variableB
              : variableA > variableB
          },
        },
        {
          title: 'Formato',
          dataIndex: 'format',
          key: 'format',
          scopedSlots: { customRender: 'format' },
          sorter: (a, b) => {
            return this.getTariffNameOption(a.format).localeCompare(
              this.getTariffNameOption(b.format)
            )
          },
        },
        {
          title: 'Estado',
          dataIndex: 'activo',
          key: 'activo',
          scopedSlots: { customRender: 'activo' },
        },
        {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          width: '100px',
          scopedSlots: { customRender: 'actions' },
        },
      ]
      return columns
    },
    filteredDataByTerm() {
      if (this.searchTerm === '') {
        return this.tariffs
      }
      const data = this.tariffs.filter(
        (item) =>
          item.codigo_registro.includes(this.searchTerm) ||
          this.getTariffNameOption(item.format).includes(this.searchTerm)
      )
      return data
    },
  },
  methods: {
    ...mapActions('tariffs', ['fetchTariffs']),
    goToViewTariff(record) {
      this.$router
        .push(`/dashboard/tariffs/${record.key}?format=${record.format}`)
        .catch(() => {})
    },
    goToEditTariff(record) {
      this.$router
        .push(`/dashboard/tariffs/${record.key}/edit?format=${record.format}`)
        .catch(() => {})
    },
    getTariffNameOption(id) {
      const tariff = this.formOptions.optionsTariffFormats?.find(
        (option) => parseInt(option.id) === id
      )
      return tariff ? tariff.name : id
    },
  },
}
</script>
