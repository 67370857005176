<template>
  <div class="h-min-content w-full">
    <ManageTariffs />
  </div>
</template>

<script>
import ManageTariffs from '@/components/Dashboard/Tariffs/ManageTariffs.vue'
export default {
  components: {
    ManageTariffs,
  },
}
</script>

<style></style>
