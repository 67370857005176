export const getDateTime = (date) => {
  const dateObj = new Date(date)
  const hours =
    dateObj.getHours() < 10 ? '0' + dateObj.getHours() : dateObj.getHours()
  const minutes =
    dateObj.getMinutes() < 10
      ? '0' + dateObj.getMinutes()
      : dateObj.getMinutes()
  return `${hours}:${minutes}`
}
export const getFormattedDate = (date) => {
  const dateObj = new Date(date)
  return `${dateObj.getFullYear()}/${
    dateObj.getMonth() + 1
  }/${dateObj.getDate()}`
}
